import { ClientSideError, ClientSideErrorType, logger } from '@dtx-company/logger'
import { VALIDATE_PHONE_NUMBER_ERROR } from '../../../../../../../../../constants/loggerErrors'
import { validatePhoneNumber } from '@app/page/src/utils/main'
export const validateInternationalPhoneNumbers = async (
  countryCode: number | string,
  phoneNumber: string
): Promise<boolean> => {
  try {
    if (String(countryCode) === '1') {
      // use Existing validation for the USA phone numbers. We know it works, we want to test a new phone validation library first
      const legacyValidation = validatePhoneNumber(
        phoneNumber.replace(/[-() ]/gi, ''),
        `${countryCode}`
      )
      return legacyValidation
    } else {
      const { parsePhoneNumber } = await import('awesome-phonenumber')
      const validation = parsePhoneNumber(`+${countryCode}${phoneNumber}`)
      // existing international phone number validation doesn't work well, always use new library
      return validation.valid
    }
  } catch (e) {
    logger.logError(
      new ClientSideError({
        type: ClientSideErrorType.ClientSideError,
        name: 'usaPhoneValidation',
        message: VALIDATE_PHONE_NUMBER_ERROR
      }),
      {
        team: 'creatorjourney'
      }
    )
    return true
  }
}
